import {graphql, StaticQuery} from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import {StrapiSharpImage} from "../utils/types"

type TestimonialType = {
  id: string
  author: string
  jobTitle: string
  quote: string
  logo: StrapiSharpImage
}

type TestimonialProps = {
  testimonial: TestimonialType
}

type StaticQueryResult = {
  testimonials: {
    edges: {
      node: TestimonialType
    }[]
  }
}

const sliderSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Testimonial: React.FC<TestimonialProps> = ({testimonial}) => (
  <div className="flex flex-col md:flex-row items-center">
    <div className="w-full lg:px-24 lg:w-2/5">
      <GatsbyImage image={testimonial.logo.localFile.childImageSharp.gatsbyImageData} alt="Testimonial logo" />
    </div>
    <div className="lg:w-3/5">
      <h3 className="font-extralight italic text-base leading-relaxed">"{testimonial.quote}"</h3>
      <p className="pt-2 text-sm text-right font-source-sans-pro">
        {testimonial.author} - {testimonial.jobTitle}
      </p>
    </div>
  </div>
)

const TestimonialList: React.FC = () => (
  <StaticQuery
    query={graphql`
      query {
        testimonials: allStrapiTestimonial {
          edges {
            node {
              id
              author
              jobTitle
              quote
              logo {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 600, quality: 90)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: StaticQueryResult): React.ReactElement => {
      return (
        <Slider className="w-full max-w-full" {...sliderSettings}>
          {data.testimonials.edges.map(({node}) => {
            return <Testimonial key={node.id} testimonial={node}></Testimonial>
          })}
        </Slider>
      )
    }}
  />
)

export default TestimonialList
